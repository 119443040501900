import React, { useContext , useEffect , useState } from 'react';
import { Link  } from "gatsby";
import { Button , StyledCart , Cursor } from "../styles/components";
import priceFormat  from "../utils/priceFormat";
import { CartContext } from "../context";


function Cart(props) {

    const { cart , delToCart}  = useContext( CartContext );
    const [ total , setTotal ] = useState(0);
    const [stripe , setStripe] = useState();

    const getTotal = () =>{

        setTotal(
            cart.reduce( (acc, current ) =>acc + current.price * current.quantity , 0  )
        )
    }

    const handleDelete = ( ele ) =>{

        delToCart(ele); 
        getTotal();
    }

    useEffect ( () =>{

        getTotal();

        setStripe(
            window.Stripe( process.env.STRIPE_PK , {betas :[ 'checkout_beta_4' ]})
        );

    },[]);

    const handleSubmit = async e =>{
        e.preventDefault();
        console.log(process.env.SUCCESS_REDIRECT);

        const { error } = await stripe.redirectToCheckout({
            items     : cart.map(({uuid, quantity}) => ({uuid, quantity})),
            successUrl: process.env.SUCCESS_REDIRECT,
            cancelUrl : process.env.CANCEL_REDIRECT
        });
        if ( error ) {
            
            throw error
        }
    }

    return (
        <StyledCart>
            <h2>Carrito de Compras</h2>
            <table>
                <tbody>
                    <tr>
                        <th>Producto</th>
                        <th>Precio</th>
                        <th>Cantidad</th>
                        <th>Total</th>
                    </tr>
                    { cart.map( ( swag , index ) =>{

                        const price_f = priceFormat( swag.price );
                        const total   = swag.quantity * swag.price;
                        const total_f = priceFormat(total);

                        return (
                            <tr key={index} >
                                <td>
                                    <img src={swag.metadata.img} alt={swag.name}/>
                                </td>
                                <td>
                                    COP {price_f}
                                    <br />
                                    <small>{swag.uuid}</small>
                                </td>
                                <td>
                                    {swag.quantity}
                                </td>
                                <td>
                                    {total_f}
                                    <Cursor 
                                        role='img' 
                                        aria-label='donut' 
                                        onClick={()=>{ handleDelete(swag) }} 
                                    >❌</Cursor>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <nav>
                <div>
                    <h3>Subtotal:</h3>
                    <small>COP {priceFormat( total )}</small>
                </div>
                <div>
                    <Link to='/'>
                        <Button type='outline'>Volver</Button>
                    </Link>
                    <Button onClick={handleSubmit} disabled={cart.length === 0}>Comprar</Button>
                </div>
            </nav>
        </StyledCart>
    );
}

export default Cart;