import React,{ useState } from 'react';
import { SelectStars  } from "../styles/components";
function Stars(props) {
    const [ starts , setStars ] = useState(5);

    return (
        <SelectStars selected={starts} >
            <span role='img' aria-label='donut' onClick ={()=>setStars(1)} >⭐️</span>        
            <span role='img' aria-label='donut' onClick ={()=>setStars(2)} >⭐️</span>        
            <span role='img' aria-label='donut' onClick ={()=>setStars(3)} >⭐️</span>        
            <span role='img' aria-label='donut' onClick ={()=>setStars(4)} >⭐️</span>        
            <span role='img' aria-label='donut' onClick ={()=>setStars(5)} >⭐️</span>        
        </SelectStars>
    );
}

export default Stars;