import React from 'react';
//import PropTypes from 'prop-types';
import { StyledJumbo  } from "../styles/components";
import {  Image } from "./";

function Jumbo({ metaData :{ description ,title} }){
    
        return (
            <StyledJumbo>
                <div>
                    <h2>{title}</h2>
                </div>
            <small>{description}</small>
            <Image name='icon' />
            </StyledJumbo>
        )
}

Jumbo.propTypes = {

};

export default Jumbo;