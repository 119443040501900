import React, { useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import priceFormat from "../utils/priceFormat";
import {  Link } from "gatsby";
import { CartContext } from "../context";
import { Tag,
SizeButton,
//QtyButton,
SizeSelect,
Button,
StyledProductDetail,
QtySelect,
 } from "../styles/components";
import { SEO ,Stars } from "./";

function ProductDetail({ 
    price , 
    id , 
    product: { name, metadata }, 
}) {

    const {
        wear,
        description,
        color
    } = metadata;
    const price_f          = priceFormat(price);
    const [size, setSize ] = useState(2);
    const [qty , setQty ]  = useState(1);
    const {addToCart }     = useContext(CartContext);

    const handleSubmit = () =>{

        const uuid = uuidv4();
        addToCart({
            uuid,
            price,
            sku:id,
            name,
            metadata,
            quantity:qty
        },true);

    }

    return (
        <StyledProductDetail>
            <SEO title={name} />
            <img src={metadata.img} alt={name} />
            <div>
                <Tag>Popular</Tag>
                <h2>{name}</h2>
                <b>{price_f}</b>
                <Stars />
                {wear&&<h3>Color: {color}</h3>}
                <small>{description}</small>
                { wear &&(
                    <SizeSelect selected={size} >
                        <SizeButton onClick={() =>setSize(1)} >XS</SizeButton>
                        <SizeButton onClick={() =>setSize(2)} >S</SizeButton>
                        <SizeButton onClick={() =>setSize(3)} >M</SizeButton>
                        <SizeButton onClick={() =>setSize(4)} >L</SizeButton>
                        <SizeButton onClick={() =>setSize(5)} >XL</SizeButton>
                    </SizeSelect>
                )}
                <p>Cantidad:</p>
                <QtySelect>
                    <button onClick={()=>( qty >1 ?setQty( qty-1 ):null)} >-</button>
                    <input type="text" disabled value={qty}/>
                    <button onClick ={ ()=>setQty(qty+1)}>+</button>
                </QtySelect>
                <Button onClick={handleSubmit} >Agregar al carrito</Button>
                <Link to='/' >Volver</Link>
            </div>
        </StyledProductDetail>
    );
}

export default ProductDetail;