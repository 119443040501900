import React from 'react';
import { Link } from "gatsby";
import priceFormat from "../utils/priceFormat";
import { StyledProducts } from "../styles/components";

function Products({ products }) {
    return (
        <StyledProducts>
            <h2>Productos</h2>
            <section>
                {products.map( ({node}) =>{

                    const price   = priceFormat( node.price);
                    const product = node.product;
                    return (
                        <article key={ node.id}>
                            <img src={product.metadata.img} alt={product.name}/>
                            <p>{product.name}</p>
                            <small>COP {price}</small>
                            <Link to={`/${node.id}`}>
                                Comprar Ahora <span role='img' aria-label='donut' >➡️</span>
                            </Link>
                        </article>
                    )
                })}
            </section>
        </StyledProducts>
    );
}

export default Products;